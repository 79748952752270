import { jsPDF } from "jspdf";
import "jspdf-autotable";

const InvoicePdf = ({ formData }) => {
  const generatePDF = async () => {
    const doc = new jsPDF({
      unit: "pt",
      format: "letter",
      orientation: "portrait",
    });

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    const marginTop = 170; // Margen superior para todas las páginas
    const marginBottom = 130; // Margen inferior para todas las páginas
    const marginLeft = 40;

    // Cargar la imagen de fondo
    const background = await fetch("/bg.png")
      .then((res) => res.blob())
      .then(
        (blob) =>
          new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          })
      );

    // Función para agregar el fondo a cada página
    const addBackgroundToPage = () => {
      doc.addImage(background, "PNG", 0, 0, pageWidth, pageHeight);
    };

    // Agregar el fondo inicial
    addBackgroundToPage();

    // Agregar título de la factura
    doc.setFont("Helvetica", "bold");
    doc.setTextColor(15, 63, 94); // Azul oscuro
    doc.setFontSize(18);
    doc.text("Invoice", pageWidth / 2, marginTop - 40, { align: "center" });

    // Información de la factura y cliente
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text(
      `Invoice Number: ${formData.invoiceNumber || "N/A"}`,
      marginLeft,
      marginTop - 20
    );

    doc.text(`Client Name: ${formData.clientName || "N/A"}`, marginLeft, marginTop);
    doc.text(
      `Client Address: ${formData.clientAddress || "N/A"}`,
      marginLeft,
      marginTop + 15
    );
    doc.text(`Client Phone: ${formData.clientPhone || "N/A"}`, marginLeft, marginTop + 30);

    // Generar datos para la tabla
    const tableData = formData.items
      .flat()
      .map((item, index) => [
        index + 1,
        item.quantity || "N/A",
        item.description || "N/A",
        `$${(item.unitPrice || 0).toFixed(2)}`,
        `$${((item.quantity || 0) * (item.unitPrice || 0)).toFixed(2)}`,
      ]);

    // Agregar tabla con márgenes superiores e inferiores globales
    doc.autoTable({
      startY: marginTop + 50, // Espacio suficiente después de la información del cliente
      margin: { top: marginTop, bottom: marginBottom, left: marginLeft },
      head: [["#", "Quantity", "Description", "Unit Price", "Subtotal"]],
      body: tableData,
      styles: { fontSize: 10 },
      headStyles: { fillColor: [23, 18, 92] }, // Azul oscuro: #17125C
      didDrawPage: () => {
        addBackgroundToPage();
      },
    });

    let cursorY = doc.lastAutoTable.finalY + 40;

    // Términos y condiciones
    const termsText =
      "WHEN YOU ENGAGE IN THEIR BUSINESS YOU ARE ACCEPTING THE FOLLOWING TERMS AND CONDITIONS. DEPOSITE OF 75% OF THE TOTAL PRICE REQUIRED ONCE AGREEMENT IS SIGNED THE REMAINING PAYMENT WOULD BE IN PAYMENT TERMS ACCORDING TO THE HOMEOWNERS AND CONTRACTOR.";
    const termsFontSize = 12;
    doc.setFontSize(termsFontSize);

    const termsLines = doc.splitTextToSize(termsText, pageWidth - marginLeft * 2);
    const termsTextHeight = termsLines.length * (termsFontSize + 2);

    // Ajustar el texto al contenido disponible
    cursorY = Math.min(cursorY, pageHeight - marginBottom - termsTextHeight);
    doc.text(termsLines, marginLeft, cursorY);

    cursorY += termsTextHeight + 40;

    // Bloque de firmas
    const signatureWidth = 100;
    const signatureHeight = 50;
    const signatureGap = 200;

    doc.setFontSize(12);

    // Firma del cliente
    doc.text("Customer Signature", marginLeft, cursorY);
    if (formData.customerSignature) {
      doc.addImage(
        formData.customerSignature,
        "PNG",
        marginLeft,
        cursorY + 10,
        signatureWidth,
        signatureHeight
      );
    } else {
      doc.text("No signature available", marginLeft, cursorY + 25);
    }

    // Firma del propietario
    const ownerSignatureX = marginLeft + signatureGap;
    doc.text("Owner", ownerSignatureX, cursorY);
    if (formData.ownerSignature) {
      doc.addImage(
        formData.ownerSignature,
        "PNG",
        ownerSignatureX,
        cursorY + 10,
        signatureWidth,
        signatureHeight
      );
    } else {
      doc.text("No signature available", ownerSignatureX, cursorY + 25);
    }

    // Fecha
    const dateX = marginLeft + signatureGap * 2;
    doc.text("Date", dateX, cursorY);
    doc.text(formData.currentDate || "No date provided", dateX, cursorY + 25);

    doc.save("invoice.pdf");
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <h2 className="text-lg font-semibold mb-4">Generate Invoice PDF</h2>
      <button
        onClick={generatePDF}
        className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
      >
        Download PDF
      </button>
    </div>
  );
};

export default InvoicePdf;
